import React, { useState, useEffect, useCallback } from "react";
import styles from './Matches.module.scss';
// import { API, Storage } from 'aws-amplify';
// import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
// import { listNotes } from './graphql/queries';
// import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from './graphql/mutations';
import {Table,Container,Row,Col,Button,Toast} from "react-bootstrap"
import PlayerPop from '../components/PlayerPop'
// import GoogleAd from '../components/GoogleAd'
import SnackAd from '../components/SnackAd'
import SnackAd2 from '../components/SnackAd2'
import PropTypes from 'prop-types';

const axios = require("axios");

function GwInfo(props) {

  const gameWeek = props.gwPage>0 ? ("Gameweek " + props.gwPage) : null

  return(
    <Container fluid className ={styles.gwInfo}>
      <Row>
        <Col xs={3}>
          <GwButton button_type="down" gwPage={props.gwPage} setUrl={props.setUrl} newUrl={props.newUrl} />
        </Col>
        <Col xs={6}>
          <h1 className ={styles.gameweekh1}>
            {gameWeek}
          </h1>
        </Col>
        <Col xs={3}>
          <GwButton button_type="up" gwPage={props.gwPage} setUrl={props.setUrl} newUrl={props.newUrl}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className ={styles.updateh5}>
            Page automatically refreshes
          </h5>
        </Col>
      </Row>
    </Container>
  )
}

function GwButton(props) {
  const newGW = props.button_type === "up" ? props.gwPage + 1 : props.gwPage - 1;
  const dirGW = props.button_type === "up" ? ">>" : "<<";
  const hideGW = newGW === 0 ? "hidden" : newGW === 48 ? "hidden" :"visible";
  const btnClass = props.button_type === "up" ? styles.btnRight : styles.btnLeft;
  return <Button className ={btnClass} variant="dark" size="lg" onClick={() => props.setUrl(props.newUrl(newGW))} style ={{visibility:hideGW}}>{dirGW}</Button>;
}

function GameHeader(props) {
  // const src_home = "https://platform-static-files.s3.amazonaws.com/premierleague/badges/t"+ {props.game_details.t1no_code}+".svg";
  const src_home =
    "https://resources.premierleague.com/premierleague/badges/t" +
    props.game_details.t1no_code +
    ".svg";
  const src_away =
    "https://resources.premierleague.com/premierleague/badges/t" +
    props.game_details.t2no_code +
    ".svg";
  const ko_f2 =
    props.game_details.fin === 1
      ? "Full Time"
      : props.game_details.start === 0
      ? props.game_details.ko_f
      : props.game_details.min;

      // <img src="https://fantasy.premierleague.com/dist/img/broadcasters/broadcaster-BT.png" srcset="/dist/img/broadcasters/broadcaster-BT.png, /dist/img/broadcasters/broadcaster-BT@2x.png 2x" alt="UK - BT Sport" class="Fixture__StyledLogo-sc-7l1xrv-11 jJohgS">

    const src_tv = props.game_details.abbr != null ? "https://resources.premierleague.com/premierleague/broadcasters/global/"+props.game_details.abbr.toLowerCase()+".png": null
    // "https://fantasy.premierleague.com/dist/img/broadcasters/broadcaster-"+props.game_details.abbr +".png"
    // "https://resources.premierleague.com/premierleague/broadcasters/global/"+props.game_details.abbr.toLowerCase()+"@x2.png";
    // const hide_tv = props.game_details.abbr === null ? "hidden" :"visible";

  function TvRow() {
    if(src_tv===null){
      return null
    }
    else {
    return (
      <Row>
        <Col>
        <img
          src={src_tv}
          className="img-responsive"
          style = {{marginTop: "-10px"}}
        />
        </Col>
      </Row>
    )
  }
}


  return (
    <Container fluid>
      <Row key={props.game_details.fid + "header"}>
        <Col xs={1} style = {{paddingLeft: "0"}}>
          <img
            width={50}
            height={50}
            src={src_home}
            className="img-responsive"
            style = {{float: "left"}}
          />
        </Col>
        <Col xs={4}>
          <h2 className={styles.homeh1}> {props.game_details.tm_h} </h2>
        </Col>
        <Col xs={2}>
          <h2 className={styles.sc}> {props.game_details.raw_sc} </h2>
        </Col>
        <Col xs={4}>
          <h2 className={styles.awayh1}> {props.game_details.tm_a} </h2>
        </Col>
        <Col xs={1} style = {{paddingRight: "0"}}>
          <img
            width={50}
            height={50}
            src={src_away}
            className="img-responsive"
            style = {{float: "right"}}
          />
        </Col>
      </Row>
      <Row style = {{marginTop: "0px"}}>
        <Col>
          <h5> {ko_f2} </h5>
        </Col>
      </Row>
      <TvRow/>
    </Container>
  );
}

function PlayersTable(props) {

  if(props.players.length==0){
    return null
  }
  else{

  return (
    <Table
      key={props.fid + props.type}
      hover
      size="sm"
      className={styles.players_table + " shadow"}
    >
      <thead className="table-dark">
        <tr>
          <th style={{textAlign:"left"}}>Player</th>
          <th>MP</th>
          <th>BPS</th>
          <th>Pts</th>
        </tr>
      </thead>
      <tbody>
        {props.players.map(plyr => (
          <tr key={plyr.plcode} onClick={()=>props.handleShow(plyr)} style={{cursor:"pointer", padding:"0"}}>
            <td
              className={
                plyr.rc !== 0
                  ? "table-danger"
                  : plyr.yc !== 0
                  ? "table-warning"
                  : null
              }
              style={{textAlign:"left"}}
            >
              {plyr.name}
            </td>
            <td>{plyr.mp_r}</td>
            <td
              className={
                plyr.bonus2 > 0 ? "table-success font-weight-bold" : null
              }
            >
              {plyr.bps}
            </td>
            <td
              className={
                plyr.gs_r > 0 || plyr.ass_r > 0
                  ? "table-success fw-bold"
                  : null
              }
            >
              {plyr.wk_pts}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
}

function ActionsTable(props) {
  // if(props.acts_new2.length==0 || props.acts_new2==null || props.acts_new2==""){
    // if(Object.keys(props.acts_new2).length == 0){
    if(props.acts_new2.summary.show == 0){
    return null;
  }
    else {
  return (
    <Table key={props.fid2 + "actionsv2"} size="sm" className={styles.actions_table + " shadow"}>
      <thead className="table-dark">
        <tr className="d-flex">
          <th className="col-4">Home</th>
          <th className="col-4"></th>
          <th className="col-4">Away</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(props.acts_new2).map(([key, value]) => {
          if(value.include==0){
            return null;
          }
          else {
          return (
          <tr key={props.fid2 + key} className="d-flex">
            <td className="col-4">{value.h}</td>
            <td className="col-4">
              <strong>{value.label}</strong>
            </td>
            <td className="col-4">{value.a}</td>
          </tr>
        )}})}
      </tbody>
    </Table>
  );
}
}

function BonusTable(props) {

if(props.bonus_rank.length==0){
  return null;
}
  else{

  function displayName(h_a_input, h_a_type, name, bps) {
    if (h_a_input === h_a_type) {
      return name + " (" + bps + ")";
    } else {
      return null;
    }
  }

  return (
    <Table key={props.fid + "bps"} size="sm" className={styles.bonus_table + " shadow"}>
      <thead className="table-dark">
        <tr className="d-flex">
          <th className="col-5">Home</th>
          <th className="col-2" style={{paddingLeft:"0",paddingRight:"0"}}>Bonus</th>
          <th className="col-5">Away</th>
        </tr>
      </thead>
      <tbody>
        {props.bonus_rank.map(bonus => (
          <tr
            key={bonus.fid + bonus.name}
            className={
              bonus.bonus2a > 0
                ? "d-flex table-success font-weight-bold"
                : "d-flex"
            }
          >
            <td className="col-5">
              {displayName(bonus.h_a, "h", bonus.name, bonus.bps)}
            </td>
            <td className="col-2">{bonus.bonus2a}</td>
            <td className="col-5">
              {displayName(bonus.h_a, "a", bonus.name, bonus.bps)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
}



// function ScoreToast(props) {
//     const [show, setShow] = useState(true);
//
//     return(
//
//      <Toast onClose={() => setShow(false)} show={show} delay={10000} autohide>
//        <Toast.Header>
//          <img
//            src="holder.js/20x20?text=%20"
//            className="rounded mr-2"
//            alt=""
//          />
//          <strong className="mr-auto">Bootstrap</strong>
//          <small>110 mins ago</small>
//        </Toast.Header>
//        <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
//      </Toast>
// )
// }


var gwLiveFlag = 1;
var gwLive = 0;
var gwPage = 0;
// const live_url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/fpl_alerts_aws_live_19';
const live_url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/fpl_alerts_aws_live';

const useMatchesState = () => {
  // const [gw, setGW] = useState(0);
  const [url, setUrl] = useState(live_url)
  const [responseData, setResponseData] = useState([]);

  //PLAYER POP UP STATE
  const [show, setShow] = useState(false);
  const [playerData, setPlayerData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (newPlayerData) => {

    setPlayerData(newPlayerData);
    setShow(true);
    };

//END

  const newUrl = (gw_x) => {
    if(gw_x === gwLive){
      return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/fpl_alerts_aws_live';
    }
      else {
        return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/fpl_alerts_aws_new/'+gw_x;
      }
    }
  var fixtures = [];
  var gwTime = "";


  if (Object.keys(responseData).length > 0) {

    fixtures = responseData.details;
    gwLiveFlag = responseData.status.gw_live;

    if(gwLiveFlag===1){
      gwLive=responseData.status.gw;
    }

    gwPage = responseData.status.gw;
    gwTime = responseData.upd_time;
    console.log("gwPage:" + gwPage);
  }

  const fetchFpl = async (url) => {
    // console.log('gwLive:'+gwLive+" gw:" + gw)
    console.log('fetch_url:'+url)
  //   let url =''
  //   if(gwLive===gw){
  //   url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/fpl_alerts_aws_live';
  // } else {
  //   url = `https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/fpl_alerts_aws_new/${gw}`;
  // }
    const response = await axios.get(url);
    setResponseData(response.data);
    console.log("fetchran");
    // setGW(response.data.status.gw);
  };

  useEffect(() => {
    fetchFpl(url);
    // setGW(responseData.data.status.gw);
    console.log("useeffect ran");
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchFpl(url);
      console.log("useeffect ran again");
    }, 60000)

    return () => {
      clearInterval(intervalId); //This is important
      console.log("clearInterval");
    }

  }, [url])
  // },)

return {gwPage, gwTime, setUrl, newUrl, fixtures,show,handleShow,handleClose,playerData};
}

const Matches = () => {

  const {
    gwPage,
    gwTime,
    setUrl,
    newUrl,
    fixtures,
    show,
    handleShow,
    handleClose,
    playerData,
  } = useMatchesState();

  // const fix0 = fixtures[0];
  const fixLength = fixtures.length;
  const fix0 = fixtures.slice(0, 1);
  // const fix00 = fix0[0];
  const fix1 = fixtures.slice(1, 2);
  const fixrem = fixtures.slice(2, fixLength);

          // <GoogleAd />
          // <GoogleAd ad_type = "horizontal"/>
                        // <AdShow ad_show={index}/>
                        // if(props.ad_show==2 || props.ad_show==5 ){
                        // <GameHeader key={99} game_details={fix0} />
                                  // <GameHeader game_details={fix0} />
                                      // <AdShow ad_show={index}/>


  return (
    <Container fluid >
      <Row>
        <Col xs={{span:12, order:2}} xl={{span:3, order:1}} style={{ marginLeft: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {1}/>
        </Col>
        <Col xs={{span:12, order:1}} xl={{span:6, order:2}} className={styles.App2}>
          <GwInfo gwPage={gwPage} setUrl={setUrl} newUrl = {newUrl} gwTime = {gwTime} />
          <PlayerPop show= {show} handleClose = {handleClose} playerData ={playerData}/>
          <div style={{ marginBottom: 30 }}>
            {fix0.map((item) => {
              // console.log(item);
              return (
              <React.Fragment key={`rf-${item.fid}`}>
              <Container fluid key={item.fid} className={styles.match_all + " shadow-lg"}>
                <GameHeader game_details={item} />
                <Row style = {{marginLeft: "0", marginRight:"0"}}>
                  <Col xs={6} sm={4} className={styles.home_col + " order-sm-1"}>
                    <PlayersTable
                      players={item.players.h}
                      fid={item.fid}
                      type="h"
                      handleShow = {handleShow}
                    />
                  </Col>
                  <Col xs={6} sm={4} className={styles.away_col + " order-sm-3"}>
                    <PlayersTable
                      players={item.players.a}
                      fid={item.fid}
                      type="a"
                      handleShow = {handleShow}
                    />
                  </Col>
                  <Col xs={12} sm={4} className={styles.actions_col + " order-sm-2"}>
                    <ActionsTable acts_new2={item.acts_new} fid2={item.fid} />
                    <BonusTable bonus_rank={item.bonus_rank} fid={item.fid} />
                  </Col>
                </Row>
              </Container>
              </React.Fragment>
            )})}
          </div>

          <Row>
            <Col>
              <SnackAd ad_type = "leader" ad_num = {2}/>
              <br></br>
            </Col>
          </Row>

          <div style={{ marginBottom: 30 }}>
            {fix1.map((item) => {
              // console.log(item);
              return (
              <React.Fragment key={`rf-${item.fid}`}>
              <Container fluid key={item.fid} className={styles.match_all + " shadow-lg"}>
                <GameHeader game_details={item} />
                <Row style = {{marginLeft: "0", marginRight:"0"}}>
                  <Col xs={6} sm={4} className={styles.home_col + " order-sm-1"}>
                    <PlayersTable
                      players={item.players.h}
                      fid={item.fid}
                      type="h"
                      handleShow = {handleShow}
                    />
                  </Col>
                  <Col xs={6} sm={4} className={styles.away_col + " order-sm-3"}>
                    <PlayersTable
                      players={item.players.a}
                      fid={item.fid}
                      type="a"
                      handleShow = {handleShow}
                    />
                  </Col>
                  <Col xs={12} sm={4} className={styles.actions_col + " order-sm-2"}>
                    <ActionsTable acts_new2={item.acts_new} fid2={item.fid} />
                    <BonusTable bonus_rank={item.bonus_rank} fid={item.fid} />
                  </Col>
                </Row>
              </Container>
              </React.Fragment>
            )})}
          </div>

          <Row>
            <Col>
              <SnackAd ad_type = "leader" ad_num = {5}/>
              <br></br>
            </Col>
          </Row>

          <div style={{ marginBottom: 30 }}>
            {fixrem.map((item) => {
              // console.log(item);
              return (
              <React.Fragment key={`rf-${item.fid}`}>
              <Container fluid key={item.fid} className={styles.match_all + " shadow-lg"}>
                <GameHeader game_details={item} />
                <Row style = {{marginLeft: "0", marginRight:"0"}}>
                  <Col xs={6} sm={4} className={styles.home_col + " order-sm-1"}>
                    <PlayersTable
                      players={item.players.h}
                      fid={item.fid}
                      type="h"
                      handleShow = {handleShow}
                    />
                  </Col>
                  <Col xs={6} sm={4} className={styles.away_col + " order-sm-3"}>
                    <PlayersTable
                      players={item.players.a}
                      fid={item.fid}
                      type="a"
                      handleShow = {handleShow}
                    />
                  </Col>
                  <Col xs={12} sm={4} className={styles.actions_col + " order-sm-2"}>
                    <ActionsTable acts_new2={item.acts_new} fid2={item.fid} />
                    <BonusTable bonus_rank={item.bonus_rank} fid={item.fid} />
                  </Col>
                </Row>
              </Container>
              </React.Fragment>
            )})}
          </div>
        </Col>
        <Col xs={{span:12, order:3}} xl={{span:3, order:3}} style={{ marginRight: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {2}/>
        </Col>
      </Row>
    </Container>
  );
}

export default Matches;
